.share-link {
  color: #808080;
  font-size: 0.9em;
  margin-right: 20px;
  align-items: center;
  vertical-align: center;
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 5px;

  & > .label {
    cursor: pointer;
    font-family: 'OpenSans', sans-serif;
  }

  &:hover {
    border-color: #3dd5e5;
    & > .label {
      color: #3dd5e5;
    }
  }

  .icon {
    margin-right: 25px;
    line-height: 12px;
    color: #3dd5e5;
  }

  #share-link-url {
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 8px;
    margin-left: 10px;
    transition: width ease-in 1s;
    right: 0;
    width: 0;
    display: none;

    &.active {
      display: inline-block;
      width: auto;
    }

    .link {
      font-family: "Courier New", sans-serif;
      font-size: 12px;
    }

    .copy-button {
      font-family: 'OpenSans', sans-serif;
      cursor: pointer;
      color: #3dd5e5;
      margin-right: 10px;
      margin-left: 10px;
      font-size: 12px;

      &.copied {
        color: #12CC8B;
        font-weight: bold;
      }

      &:hover{
        color: darken(#3dd5e5, 20%)
      }
    }
  }
}
