.type-spinner {
  background-color: RGBA(51,189,221,0.12);
  padding: 10px 15px;
  border-radius: 20px;
  display: inline-block;
}

.type-spinner .blob {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: inline-block;
  background-color: #aaaaaa;
  margin: 0 2px 0 2px;
  animation: 1.0s typing-blob ease-out infinite;
}

.type-spinner .blob:nth-of-type(2) {
  animation-delay: 0.1s;
}

.type-spinner .blob:nth-of-type(3) {
  animation-delay: 0.2s;
}

@keyframes typing-blob {
  15% {
    transform: translateY(-35%);
    opacity: 0.7;
  }
  30% {
    transform: translateY(0%);
    opacity: 1;
  }
}
