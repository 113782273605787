.full-page {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255,255,255,0.90);
  z-index: 100;
  transition: .3s;
  transition-timing-function: ease-out;
  opacity: 0;
  pointer-events: none;
}
.full-page.active {
  opacity: 1;
  pointer-events: default;
}
.helper {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
}
.loading-message {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  font-family: 'lato', sans-serif;
  color: #777;
}
.loading-message > div {
  margin: 0 auto;
  width: 94%;
  max-width: 400px;
  text-align: center;
}
.loading-message p {
  margin-top: 5px;
}
h3.message {
  padding-top: 20px;
  line-height: 1.3em;
}


@media (max-width: 420px) {
  .full-page {
    width: 50%;
    position: absolute;
  }
}
