.accordion {
  border: 1px solid #eee;
  margin: 12px;
  border-radius: 5px;

  &-tab {
    display: block;
    min-height: 40px;
    cursor: pointer;
  }

  &-content {
    position: relative;
    overflow: hidden;
    max-height: 800px;
    opacity: 1;
    transform: translate(0, 0);
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 0 12px;
  }

  &-content, ul.accordion-list li.accordion-item i:before, ul.accordion-list li.accordion-item  i:after {
    transition: all 0.4s ease-in-out;
  }

  ul.accordion-list {
    list-style: none;
    padding: 0;
    margin: 0;
    & li.accordion-item {
      position: relative;
      margin: 0;
      padding: 12px 12px 12px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      & i{
        font-size: 2em;
        align-self: start;
        position: relative;
        left: -10px;
      }
      & input[type=checkbox] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:not(:checked) ~ div.accordion-content {
          margin-top: 0;
          margin-bottom: 0;
          max-height: 0;
          opacity: 0;
          transform: translate(0, 50%);
        }
      }
    }
  }
}
