.poly-loader {
  width: 80px;
  height: 80px;
  position: relative;
  margin: 0 auto;
}
@keyframes wietse {
  0% {width: 41px; float: left;}
  24% {width: 1px; float: left;}
  26% {width: 1px; float: right;}
  50% {width: 41px; float: right;}
  100% {width: 41px; float: right;}
}
.poly-loader > div {
  width: 41px;
  height: 4px;
  position: absolute;
  border-radius: .5px;
  overflow: hidden;
}
.poly-loader > div:after {
  content: "";
  display: block;
  width: 41px;
  height: 4px;
  background: #048dbe;
  animation-duration: 3s;
  animation-iteration-count:infinite;
  animation-timing-function: ease-in-out;
  animation-name: wietse;
}
.poly-loader > div:nth-child(1){
  top: 10px;
  left: 36px;
  transform: rotate(30deg);
  z-index: 6;
}
.poly-loader > div:nth-child(2){
  top: 39px;
  right: -13px;
  transform: rotate(90deg);
  z-index: 5;
}
.poly-loader > div:nth-child(3){
  top: 68px;
  right: 4px;
  transform: rotate(150deg);
  z-index: 4;
}
.poly-loader > div:nth-child(4){
  top: 68px;
  left: 3px;
  transform: rotate(210deg);
  z-index: 3;
}
.poly-loader > div:nth-child(5){
  top: 39px;
  left: -14px;
  transform: rotate(270deg);
  z-index: 2;
}
.poly-loader > div:nth-child(6){
  top: 10px;
  left: 3px;
  transform: rotate(330deg);
  z-index: 1;
}
.poly-loader > span {
  display: block;
  width: 40px;
  height: 3px;
  position: absolute;
  border-radius: 1px;
  overflow: hidden;
}
@keyframes deVries {
  0% {width: 0; float: left;}
  50% {width: 0px; float: left;}
  74% {width: 40px; float: left;}
  76% {width: 40px; float: right;}
  100% {width: 0px; float: right;}
}
.poly-loader > span:after {
  content: "";
  display: block;
  width: 40px;
  height: 3px;
  background: #048dbe;
  animation-duration: 3s;
  animation-iteration-count:infinite;
  animation-timing-function: ease-in-out;
  animation-name: deVries;
}
.poly-loader > span:nth-of-type(1){
  top: 60px;
  left: 20px;
  transform: rotate(-90deg);
}
.poly-loader > span:nth-of-type(2){
  top: 30px;
  left: 3px;
  transform: rotate(30deg);
}
.poly-loader > span:nth-of-type(3){
  top: 30px;
  right: 3px;
  transform: rotate(150deg);
}
