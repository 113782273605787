.deliveryInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  flex: 999;
}

.deliveryInfo.delivered {
  background: #12CC8B;
}
.deliveryInfo.non-delivery {
  background: #2a2a2a;
}

@media (max-width: 420px) {
  .deliveryInfo {
    overflow-y: auto;
  }

  .d-info-eta-container {
    padding: 15% 0% 15% 0%;
  }
  .d-info-status-container{
    height: 45%;
  }
}

#parcel-info-container {
  background-color: #FFFFFF;
  border-right: 1px solid #eeeeee;
}

#parcel-info, #parcel-status {
  width: 85%;
}

#parcel-info {
  margin-top: 24px;
}

#parcel-info h1, #parcel-status h1{
  font-family: 'Cubano', sans-serif;
  font-size: 1.4em;
  margin-top: 15px;
  margin-bottom: 20px;
  font-weight: normal;
}

#parcel-status h1{
  margin-bottom: 0;
  font-weight: normal;
}

#parcel-info .label{
  margin-bottom: 15px;
}

#parcel-info .label p, #parcel-status .label p{
  font-family: 'OpenSans-Regular', sans-serif;
  font-size: 0.8em;
}

#parcel-info .label .header, #parcel-status .label .header{
  margin-bottom: 5px;
}

#parcel-info .label .value, #parcel-status .label .header{
  font-family: 'OpenSans-Bold', sans-serif;
  font-weight: bold;
  margin-top: 0;
  font-size: 0.9em;
}

#parcel-status .label{
  .disabled {
    &.value, &.header {
      color: #d3d3d3;
    }
  }
}

@media(max-width: 768px){
  #delivery-info-container {
    .circle-btn {
      top: auto;
      left: auto;
      right: 12px;
      bottom: 12px;
      position: fixed;
    }
  }
}

@media (max-width: 420px) {
  #parcel-status .label .header,
  #parcel-status .label p {
    font-size: 0.6em;
  }

  #parcel-info .label .value {
    font-size: 0.8em;
  }

  #delivery-info .circle-btn {
    top: auto;
    left: auto;
    right: 12px;
    bottom: 12px;
    position: fixed;
  }
}

@media(max-width:376px){
  #delivery-info {
    .circle-btn {
      top: auto;
      left: auto;
      right: 12px;
      bottom: 12px;
      position: fixed;
    }
  }
}

@media(max-width:320px){
  #delivery-info {
    .circle-btn {
      top: auto;
      left: auto;
      right: 12px;
      bottom: 12px;
      position: fixed;
    }
  }
}

#parcel-info .label .value span{
  display: block;
}

#parcel-status{
  margin-bottom: 15px;

  .parcel-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 38px;
    margin-bottom: 15px;

    h1 {
      flex: 1;
      margin-top: 0;
    }
    .modal-link {
      color: #3dd5e5;
      cursor: pointer;
      font-family: 'OpenSans-Regular', sans-serif;
      font-size: 0.8em;

      &:hover {
        color: #2da1ad
      }
    }
  }
}

#parcel-status .label{
  min-height: 40px;
}

#parcel-status .label .value{
  margin-top: 0;
  color: #666666;
}

#parcel-status .step-progress li:first-child .label{
  position: relative;
  top: -15px;
}

#parcel-status .step-progress li:last-child .label {
  position: relative;
  top: 23px;
}

#parcel-status .timestamp{
  font-size: .72em;
  font-weight: normal !important;
  float: right;
  color: #999999;
}

#parcel-status .step-progress {
  padding-top: 20px;
}

#parcel-status .step-progress li.success p:not(.timestamp){
  color: #12CC8B;
}

#parcel-status .step-progress li.failed:before {
  border-left-color: #d32d4f;
}

#parcel-status .step-progress li.failed .step-circle {
  background-color: #d32d4f;
}

#parcel-status .step-progress li.failed .step-circle:after {
  border-color: #d32d4f;
}

#parcel-status .step-progress li.failed p:not(.timestamp){
  color: #d32d4f;
}

#parcel-status .step-progress li.failed .timestamp{
  color: #d32d4f;
}

#parcel-status {
  .popup{
    .popup-content {
      width: auto;
      height: auto;
      max-width: none;
      padding: 0;
    }
    .close-button {
      position: fixed;
      right: 25px;
      top: 25px;
    }
  }
}

#eta-info {
  text-align: center;
  font-size: 1.5em;
  font-family: OpenSans-Bold, sans-serif;
  margin-top: -15px;
  width: 100%;

  .label {

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 32px;

      p {
        text-align: center;
      }
    }

    .header {
      text-transform: uppercase;
      font-weight: bold;
    }

    .value {
      font-size: 3em;
      font-family: Cubano, sans-serif;
      margin: 0;
    }
  }

  &.canceled {
    .header {
      color: #d32d4f;
      font-size: 2.5em;
      font-family: Cubano, sans-serif;
      padding: 0 40px 0 40px;

      @media (max-width: 768px) {
        font-size: 2.5em !important;
      }
    }
  }

  &.not-delivered {
    .header {
      color: #d32d4f;
    }
  }

  &.delivered p {
    color: #12CC8B;
  }
}

.panel-notification {
  display: inline-flex;
  margin: 15px auto;
  height: 70px;
  width: 460px;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.12);
  opacity: 0;
  color : #f59123;
  align-items: center;
  animation: fade-in 800ms linear 1s forwards;
  background-color: white;

  &-container {
    margin: 50px auto;
  }

  .icon, .description {
    display: inline-block;
    height: auto;
    padding-left: 10px;
  }

  .icon {
    height: 36px;
    animation: shake-with-delay 5.82s linear infinite both;
    i {
      font-size: 1.5em;
      width: 40px;
    }
  }

  .description {
    text-align: left;
    font-size: 13px;
    width: 420px;
    padding-right: 10px;
  }

  &.error {
    color : #d32d4f
  }

  &.info {
    margin-bottom: 0px;
    color: #000000;
  }
}

#shipping-history {

  .modal-content {
    min-width: 650px;
    height: auto;
  }

  .popup-body > h1 {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 24px;
  }

  .popup-controls {
    display: block;
    text-align: right;
  }

  .header {
    .number, .date, .status {
      font-family: 'Cubano', sans-serif;
      font-size: 20px;
    }

    .number {
      color: #ccc;
      text-align: center;
    }

    .date {
      color: #000;
      padding-left: 5px;
    }

    .status {
      color: #3dd5e5;
      padding-left: 4px;

      &.not-delivered, &.canceled {
        color: #d32d4f
      }
    }
  }
}

.step-progress {
  li {
    &:last-child {
      .value {
        margin-bottom: 0;
      }
    }

    .label {
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.panel-service-review-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

#service-review {
  background: #FFFFFF 0 0 no-repeat padding-box;
  padding: 24px;
  box-shadow: 0 2px 4px #0000001F;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: 330px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 6px !important;
  }

   > div {
     height: 166px;

     @media (max-width: 768px) {
       height: auto;
     }

     .heading {
       font-family: ClanPro-Bold, sans-serif;
       font-size: 14px;
       color: #201047 !important;
     }

     .sub-heading{
       font-family: ClanPro-News, sans-serif;
       font-size: 12px;
       color: #888888 !important;
     }

     .buttons-container {
       height: 100px;
       padding: 10px;
       display: flex;
       justify-content: space-around;
       align-items: center;

       .icon-buttons {
         height: 60px;
         width: 60px;
         cursor: pointer;
         border-radius: 30px;
         background: #F2F2F2 0 0 no-repeat padding-box;
         display: flex;
         flex-direction: column;
         align-items: center;
         color: #888888;

         svg {
           height: 60px;
           width: 36px;
         }

         .text {
           font-family: ClanPro-News, sans-serif;
           font-size: 12px;
           color: #888888 !important;
           width: 140px;
           margin-top: 12px;
         }

         &.positive:hover {
           background-color: #00D763 !important;
           svg {
             color: #fff;
           }
           .text {
             color: #00D763 !important;
           }
         }

         &.negative:hover {
           background-color: #ff8e5e !important;
           svg {
             color: #fff;
           }
           .text {
             color: #ff8e5e !important;
           }
         }
       }
     }

     &.feedback-container {
      width: 326px;
      height: 88px;

       p {
         text-align: left;
       }

       .feedback {
         color: #CCCCCC !important;
         font-family: ClanPro-News, sans-serif;
         font-size: 12px;
         margin-top: 24px;

         .text-vote {
           font-family: ClanPro-Bold, sans-serif;
           color: #ff8e5e !important;
           font-size: 12px;

           &.positive {
             color: #00D763 !important;
           }
         }
       }
     }
  }
}

.hidden-md {
  @media (max-width: 768px) {
    display: none !important;
  }
}
