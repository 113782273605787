#sms-subscription-form-container {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: auto;
  background-color: white;
  border-radius: 5px;

  .text-message-form{
    flex-direction: column;
    display: flex;
    height: 100px;
    justify-content: flex-start;
    margin-bottom: 15px;
  }

  .text-message-form-title{
    align-items: flex-end;
    display: flex;
    flex: 1;
    font-family: 'Cubano', sans-serif;
    font-size: 37px;
    padding: 0 0 15px 0;
    letter-spacing: 0;
  }

  .text-message-form-detail{
    flex: 1;
    display: flex;
    font-family: 'OpenSans-Bold', sans-serif;
    font-size: 20px;
    color: #666666;
  }

  .text-message-input-field{
    display: flex;
    align-items: center;
    height: 75px;
    z-index: 1;
    -webkit-box-shadow: 0px 15px 85px -30px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 15px 85px -30px rgba(0,0,0,0.35);
    box-shadow: 0px 15px 85px -30px rgba(0,0,0,0.35);

    &.has-errors{
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      border: 1px solid #D32D4F;
      color: #D32D4F;
      input {
        color: #D32D4F;
      }
    }
  }

  .text-message-input {
    display: flex;
    border-radius: 5px;
    height: 45px;
    border: none;
    padding: 0px 0px 0px 10px;
    font-size: 14px;
    letter-spacing: -1px;
    font-family: OpenSansRegular,'Lato', sans-serif;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(202,202,202,1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(202,202,202,1);
    box-shadow: 0px 2px 5px 0px rgba(202,202,202,1);
  }
  .text-message-save-number{
    position: relative;
    z-index: 0;
  }
  .flat-btn-save-number {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(192,192,192,1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(192,192,192,1);
    box-shadow: 0px 2px 5px 0px rgba(192,192,192,1);
    font-family: 'Cubano', sans-serif;
    font-size: 20px;
    height: 60px;
    width: 215px;
    border-radius: 5px;
    line-height: 50px;
    transition: .3s;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    border: 0;
    outline: none !important;
    background-color: #DDDDDD;
  }

  .flat-btn-save-number.green {
    color: #FFFFFF;
    background-color: #12cc8b !important;
  }

  .flat-btn-save-number.disabled{
    color: #FFFFFF;
    background-color: #12cc8b !important;
    opacity: .5;
  }


  .flat-btn-save-number.green:hover {
    background-color: #0fbf81 !important;
  }

  .popup{
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  .close-icon{
    align-self: center;
    width:20px;
    height: 20px;
    background-image: url(./icons/baseline-close-24px.svg);
    position: relative;
    bottom: 50px;
    left: 90px;
    opacity: .5;
    transition: opacity .3s ease-out;
    -moz-transition: opacity .3s ease-out;
    -webkit-transition: opacity .3s ease-out;
    -o-transition: opacity .3s ease-out;
  }

  .close-icon:hover{
    cursor: pointer;
    opacity: 1;
  }

  .popup-content {
    width: auto;
    height: auto;
    max-width: none;
    padding: 0;
  }

  @keyframes slide-down {
    from {top: 35%;}
    to {top: 50%;}
  }

  @keyframes slide-up {
    from {top: 50%; opacity: 1}
    to {top: 35%; opacity: 0}
  }

  .close-button {
    position: fixed;
    right: 25px;
    top: 25px;
  }
  .popup-controls{
    display: none;
  }
  .modal{
    z-index: 2;
  }

  .flat-btn-save-number{
    position: relative;
    font-size: 16px;
    width: 150px;
    height: 40px;
  }

  .text-message-save-number{
    position: relative;
    z-index: 0;
    margin: 30px 0 0 0;
  }
}

#sms-country-code-selector {
  display: flex;
  height: 32px;
  font-family: Cubano, sans-serif;
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .drop-down-list{
    flex-direction: column;
    width: 115px;
  }

  .drop-down-list-end{
    width:25px;
    height: 25px;
  }

  .text{
    font-size: 23px;
    padding: 0 5px 0 20px;
    text-transform: uppercase;
  }

  .drop-down{
    display: flex;
    height: 28px;
    justify-content: space-between;
    cursor: pointer;
    opacity: 1;
    transition: opacity .3s ease-out;
    -moz-transition: opacity .3s ease-out;
    -webkit-transition: opacity .3s ease-out;
    -o-transition: opacity .3s ease-out;

    &:hover{
      opacity: 0.5;
    }

    .flag-box {
      //margin-right: 2px;
      //margin-bottom: 2px;
    }
    span {
      vertical-align: center !important;
    }
  }

  .drop-down-content {
    .drop-down {
      margin: 9px 0 9px 0;
    }
  }

  .flag-icon-background{
    align-self: flex-end;
    height: 22px;
    width: 26px;
    background-repeat: no-repeat;
  }

  .expand-less-icon{
    align-self: center;
    width:25px;
    height: 25px;
    background-image: url(./icons/baseline-expand_less-24px.svg);
  }

  .expand-more-icon{
    align-self: center;
    width:25px;
    height: 25px;
    background-image: url(./icons/baseline-expand_more-24px.svg);
  }

  .expand-more{
    border-radius: 0 0 5px 5px;
    background-color: white;
    -webkit-box-shadow: 0px 40px 85px -30px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 40px 85px -30px rgba(0,0,0,0.35);
    box-shadow: 0px 40px 85px -30px rgba(0,0,0,0.35);
    height: 150px;
    overflow-y: auto;
  }

  .expand-less{
    display: none;
  }

  .input-field {
    display: flex;
    //flex-direction: column;
    //margin-top: 1px;
    align-items: center;
  }

  .input, .phone-code-prefix {
    font-size: 23px;
    font-family: Cubano, sans-serif;
  }

  .input {
    display: flex;
    height: 26px;
    width: 100%;
    margin-left: -5px;
    background: transparent !important;
  }

  .selected{
    opacity: 0.5;

  }
  .selected:hover{
    cursor: unset;
  }
}

@media(max-width: 768px) {
  #sms-country-code-selector {
    .drop-down .text{
      font-size: 19px;
      padding: 0 5px 0 10px;

    }

    .drop-down-content {
      .text{
        font-size: 19px;
        padding: 0 5px 0 10px;
        transform: translateX(0) translateY(calc(2% - 0.5px)); /*to fix the blurred effect*/
      }
      .flag-icon-background{
        transform: translateX(0) translateY(calc(0% - 0.5px)); /*to fix the blurred effect*/
      }
    }
    .flag-icon-background{
      align-self: center;

      height: 22px;
      width: 22px;
      background-repeat: no-repeat;
    }
    .input-field .input{
      font-size: 19px;
      width: 190px;
      margin: 0;
    }

    .drop-down, .drop-down-list{
      width: 93px;
    }

    .expand-more{
      border-radius: 0 0 5px 5px;
      -webkit-box-shadow: 0px 40px 85px -30px rgba(0,0,0,0.35);
      -moz-box-shadow: 0px 40px 85px -30px rgba(0,0,0,0.35);
      box-shadow: 0px 40px 85px -30px rgba(0,0,0,0.35);
      height: 100px;
      overflow-y: auto;
    }

    .drop-down-content .drop-down{
      margin: 5px 0 5px 0;
    }
  }

  @media(max-width: 768px){
    #sms-subscription-form-container {
      max-width: 452px;

      .text-message-form {
        height: auto;
      }
    }

    .modal-dialog-centered {
      justify-content: center;
    }
  }
}

