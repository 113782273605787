.popup{
  -webkit-box-shadow: 0px 2px 5px 0px rgba(202,202,202,1);
  -moz-box-shadow: 0px 2px 5px 0px rgba(202,202,202,1);
  box-shadow: 0px 2px 5px 0px rgba(202,202,202,1);
  margin: 5px;
  opacity: 1;
  transition: 1s;
  display: none;

  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}

.popup.show {
  display: flex;
  animation-name: fade-in;
}

.popup.modal:not(.show) {
  animation-name: fade-out;
}

.popup.modal:not(.show) .popup-content, .popup:not(.modal):not(.show){
  animation-name: slide-up;
}

.popup-content{
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  max-width: 24rem;
  min-width: 340px;
  width: 100%;
}

.popup.show .popup-content, .popup:not(.modal).show{
  animation-name: slide-down;
}

.popup:not(.modal) {
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.5rem;
  top: 15%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-width: 24rem;
  min-width: 340px;
  width: 100%;
}

.popup.modal {
  position: fixed;
  background: rgba(0, 0, 0, .6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  z-index: 20;
  display: flex;
}

.popup.modal .popup-content {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-button {
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
  overflow: hidden;
  cursor: pointer;
}
.close-button:hover::before, .close-button:hover::after {
  opacity: 1;
}
.close-button::before, .close-button::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #333333;
  border-radius: 5px;
  opacity: 0.3;
}
.close-button:before {
  transform: rotate(45deg);
}
.close-button:after {
  transform: rotate(-45deg);
}

.popup .close-button{
  float: right;
}

.popup .popup-controls{
  text-align: center;
  margin-bottom: 12px;
}

.popup .control-button{
  width: 120px;
  height: 40px;
  line-height: 40px;
  margin: 0px 12px;
  opacity: 1;
}

@keyframes slide-up {
  from {top: 15%; opacity: 1}
  to {top: 0; opacity: 0}
}

@keyframes slide-down {
  from {top: 0;}
  to {top: 15%;}
}

@keyframes fade-out {
  from {opacity: 1}
  to {opacity: 0;}
}

@keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 1;}
}

@media (max-width: 440px) {
  .popup:not(.modal){
    margin: 5px 0 5px 0;
  }

  .popup:not(.modal), .popup-content{
    min-width: 340px !important;
    width: 340px;
  }
}
