#root {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.main-container {
  display: flex;
  height: auto;
  width: 90%;
  min-width: 1000px;
  position: relative;
  border-radius: 6px;
  align-self: center;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
}

@media(max-height: 925px) {
  .main-container{
    align-self: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.main-container:before{
  content: "";
  position: absolute;
  bottom: 0;
  width: 95%;
  height: 95%;
  margin-left: -47.5%;
  left: 50%;
  background: transparent;
  -webkit-box-shadow: 0px 4px 50px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px 4px 50px 0px rgba(0,0,0,0.35);
  box-shadow: 0px 4px 50px 0px rgba(0,0,0,0.35);
  z-index: -1;
}

.main-container > .wrapper{
  height: 100%;
  background: #F8F9FB;
  border-radius: 6px;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.main-container > .wrapper > .header-nav {
  background-color: #FFFFFF;
  border-bottom: 1px solid #eeeeee;
}

.main-container > .wrapper > .header-nav a {
  text-decoration: none;
  outline: none;
  color: #808080;
  font-family: 'OpenSans-Regular', sans-serif;
  font-size: 1em;
}

.main-container > .wrapper .trunkrs-redirect{
  height: 40px;
  width: 125px;
  margin-right: 15px;
}

.main-container > .wrapper .trunkrs-redirect:hover {
  background-color: transparent;
}

.main-container > .wrapper .trunkrs-redirect:hover a{
  color: #92D400;
}

.main-container > .wrapper .sign-out-link{
  margin-right: 20px;
}
.main-container > .wrapper .sign-out-link a {
  color: #d32d4f;
  font-family: 'OpenSans-Regular', sans-serif;
  font-size: 14px;
}

.main-container > .wrapper .sign-out-link:hover a {
  color: #6b1627;
}

.delivery-info {
  display: flex;
  justify-content: center;
  flex: 1;
  z-index: 9 !important;
}

.on-top{
  position:relative;
  z-index: 6;
}

.chat-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.sms.circle-btn {
  background-color: #12CC8B;
  color: white;
  font-family: Cubano, sans-serif;
  font-size: 18px;
  position: absolute;
  clip-path: circle(50% at 50% 50%);
  top: 90%;
  right: 2%;
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: .3s;
  z-index: 12;
}

.sms.circle-btn:hover {
  background-color: #0fad76 !important;
  cursor: pointer;
}

@media(max-width: 768px){
  .main-container{
    min-width: auto;
  }
  .panel-notification-container {
    margin-bottom: 0;
    align-self: flex-end;
  }
}

@media(max-width:420px){
  .main-container{
    min-width: auto;
  }
}

@media(max-width:376px){
  .main-container{
    min-width: auto;
  }
}

@media(max-width:320px){
  .main-container{
    min-width: auto;
  }
}

@media(max-width: 1365px){
  .main-container{
    width: 95%;
  }
}
