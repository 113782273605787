.on-boarding-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white;
  height:660px;
  width: 1152px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 22px 27px -14px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px 22px 27px -14px rgba(0,0,0,0.35);
  box-shadow: 0px 22px 27px -14px rgba(0,0,0,0.35);
}
#on-boarding-container .on-boarding-flow{
  display: flex;
  width: 500px;
  flex-direction: row;
  overflow: hidden;
}

.on-boarding-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  visibility: hidden;
}

#on-boarding-flow .shown{
  animation-name: flow-fade-in;
  animation-duration: 1500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transition: visibility 1000ms cubic-bezier(0.03, 0.02, 0, 0.97);
}
#on-boarding-container .on-boarding-nav{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  margin: 0 0 30px 0;
  padding:0 50px 0 50px;
}
.on-boarding-button {
  display: flex;
  flex: 1;
  font-family: 'Cubano', sans-serif;
  font-size: 16px;
}
.on-boarding-dots{
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;

}
.on-boarding-back{
  color: #9B9B9B;
  padding: 10px 25px 10px 25px;
  border-radius: 5px;
  transition: background-color 500ms ease;
}
.on-boarding-back:hover{
  background-color: #e8e7e7;
  cursor: pointer;
}
.on-boarding-next-ready{
  display: flex;
  color: white;
  background: #12CC8B;
  padding: 10px 25px 10px 25px;
  border-radius: 5px;
  transition: background-color 500ms ease;
}
.on-boarding-next-ready:hover{
  background: #12b178;
  cursor: pointer;
}
#on-boarding-container .next-ready{
  justify-content: flex-end;
}
@keyframes flow-fade-in {
  0% {
    visibility: visible;
    opacity: 0;
  }
  100%{
    visibility: visible;
    opacity: 1;
  }
}

#on-boarding-container .on-boarding-image{
  height: 380px;
  width: 390px;
  position: relative;
}

#on-boarding-container .waving-hand{
  background-image: url("./images/waving-hand-illu.svg");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

#on-boarding-container .status{
  background-image: url("./images/status-illu.svg");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
#on-boarding-container .time{
  background-image: url("./images/time-illu.svg");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
#on-boarding-container .chat{
  background-image: url("./images/chat-illu.svg");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
#on-boarding-container .sms{
  background-image: url("./images/sms-illu.svg");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
#on-boarding-container .on-boarding-text-1{
  font-family: Cubano, sans-serif;
  font-size: 23px;
  position: relative;
  bottom: 20px;
  width: 500px;
  text-align: center;
}
#on-boarding-container .on-boarding-text-2{
  font-family: OpenSans-Bold, 'Lato', sans-serif;
  font-size: 16px;
  color: #9B9B9B;
  width: 400px;
  text-align: center;
}
#on-boarding-container .on-boarding-text-1:hover, .on-boarding-text-2:hover{
  cursor: context-menu;
}
/*DOTS*/
.dots{
  -webkit-appearance: none;
  -moz-appearance: none;
  right: 0;
  bottom: 0;
  left: 0;
  height: 10px;
  width: 10px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  z-index: 1000;
}
.dots:hover {
  background: #9faab7;
}
.dots:checked {
  background: #12CC8B;
}
.dots:checked::before {

  position: absolute;
  display: inline-block;
  text-align: center;
  line-height: 40px;
}
.dots:checked::after {
  background: #40e0d0;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}
.dots.radio {
  border-radius: 50%;
}
.dots.radio::after {
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .on-boarding-container{
    width: 100%;
    height: 100%;
  }
  .on-boarding-content{
    width: 500px;
  }

  #on-boarding-container .on-boarding-flow{
    display: flex;
    width: 500px;
    flex-direction: row;
    overflow: hidden;
  }

  #on-boarding-container.on-boarding-image{
    height: 350px;
    width: 350px;
  }
  #on-boarding-container .on-boarding-text-1{
    font-size: 29px;
    max-width: 80%;
  }
  #on-boarding-container .on-boarding-text-2{
    font-size: 18px;
    /*width: 500px;*/
  }
  .on-boarding-button{
    font-size: 16px;
  }

  #on-boarding-container .on-boarding-nav{
    margin: 0 0 30px 0;
    padding:0 15px 0 15px;
  }
  .on-boarding-dots{
    position: fixed;
    height: unset;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
  }
}

@media (max-width: 420px) {
  .on-boarding-container{
    width: 100%;
    height: 100%;
  }
  .on-boarding-content{
    width: 400px;
  }

  #on-boarding-container .on-boarding-flow{
    display: flex;
    width: 400px;
    flex-direction: row;
    overflow: hidden;
  }

  #on-boarding-container .on-boarding-image{
    height: 220px;
    width: 220px;
  }
  #on-boarding-container .on-boarding-text-1{
    font-size: 24px;
    width: 300px;
  }
  #on-boarding-container .on-boarding-text-2{
    font-size: 14px;
    width: 300px;
  }
  .on-boarding-button{
    font-size: 14px;
    letter-spacing: -1px;
  }

  #on-boarding-container .on-boarding-nav{
    margin: 0 0 30px 0;
    padding:0 15px 0 15px;
  }
  .on-boarding-dots{
    position: fixed;
    height: unset;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
  }
}


@media (max-width: 375px) {
  #on-boarding-container .on-boarding-image{
    height: 220px;
    width: 220px;
  }
}

@media (max-width: 320px) {
  .on-boarding-container{
    width: 100%;
    height: 100%;
  }
  .on-boarding-content{
    width: 200px;
  }

  #on-boarding-container .on-boarding-flow{
    display: flex;
    width: 200px;
    flex-direction: row;
    overflow: hidden;
  }

  #on-boarding-container .on-boarding-image{
    height: 220px;
    width: 220px;
  }
  #on-boarding-container .on-boarding-text-1{
    width: 250px;
  }
  #on-boarding-container .on-boarding-text-2{
    font-size: 12px;
    width: 250px;
  }
  .on-boarding-button{
    font-size: 11px;
    letter-spacing: -1px;
  }

  #on-boarding-container .on-boarding-nav{
    margin: 0 0 30px 0;
    padding:0 15px 0 15px;
  }
  .on-boarding-dots{
    position: fixed;
    height: unset;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
  }
}
