/* MOBILE */

@media (max-width: 768px) {

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  .full-page {
    width: 100% !important;
  }

  .modal-link {
    display: none;
  }

  .main-container{
    margin: 0;
    height: 100%;
    width: 100%;
  }

  .main-container .header-nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10%;
    z-index: 10;
  }

  .main-container .content-body{
    position: absolute;
    top: 10%;
    height: 90% !important;
    width: 100%;
  }

  .main-container .btn-arrow {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 5px;
    margin-right: 20px;
    margin-bottom: 2%;
  }

  .main-container .btn-arrow i {
    border: solid black;
    border-width: 0 2px 2px 0;
    width: 3px;
    height: 3px;
    display: inline-block;
    padding: 4px;
  }

  .main-container .btn-arrow .btn-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .main-container .btn-arrow .btn-down {
    transform: rotate(45deg) !important;
    -webkit-transform: rotate(45deg) !important;
  }

  #parcel-info {
    position: fixed;
    top: 10%;
    background: #FFFFFF;
    width: 100%;
    padding: 0px 25px;
    z-index: 999;
    max-height: 0;
    transition: max-height .25s ease-in-out;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    overflow: hidden;

  }

  #parcel-info.expand {
    max-height: 450px;
  }

  #delivery-info-container{
    flex: auto;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 17;
  }

  #parcel-eta-info-container{
    max-width: 100%;
    flex: auto;
    order: 2;
    min-height: 300px;

    .panel-notification{
      background-color: white;
      position: relative;
      width: 100vw;
      margin: -1px 0 0 -24px;
      border-radius: 0;
      opacity: 1;
      animation: none;
      border-left: none;
      border-right: none;

      .icon {
        display: none;
      }

      .description {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 14px;
        width: auto;
        font-weight: normal;
      }
    }
  }

  #parcel-eta-info-container > div {
    justify-content: flex-start !important;
  }


  #parcel-eta-info-container #eta-info {
    text-align: left;
    margin: 0 0 0 24px;
    font-size: 0.8em;
    padding-right: 35px
  }

  #parcel-eta-info-container #eta-info .label .header {
    font-size: 1.2em;
    margin-bottom: 12px;
  }

  #parcel-info-container {
    height: auto !important;
    max-width: 100%;
    flex: auto;
    order: 3;
    z-index: 12;
  }
  #parcel-status {
    margin-bottom: 12px;
  }

  #parcel-info {
    margin-top: 0px !important;
  }

  #parcel-status .step-progress .label{
    font-size: 1.5em;
  }

  #parcel-info > h1 {
    display: none;
  }
  #parcel-info .label {
    font-size: 1.2em;
  }

  #parcel-info .links-container {
    width: auto;
    margin-left: -25px;
    margin-right: -25px;
  }

  #parcel-info .links {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
  }

  #parcel-info .links a{
    margin-left: 25px;
    text-decoration: none;
    outline: none;
    font-family: 'OpenSans-Regular', sans-serif;
    font-size: 1em;
    text-align: left;
    color: #808080;
    font-weight: bold;
  }

  #parcel-info .links.sign-out a {
    color: #d32d4f;
  }

  #parcel-info .links.sign-out:hover a{
    color: #6b1627;
  }

  #parcel-info .links.redirect:hover a{
    color: #505050;
  }

  #delivery-info-container .chat-btn {
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    right: 0;
    top: 15%;
    z-index: 8;
  }
  #delivery-info-container .chat-btn .clickable {
    height: 70px;
    width: 35px;
    display: flex;
    background: white;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
    border-radius: 5px 0px 0px 5px;
    border: none;
    outline:none;
  }

  #delivery-info .text, .text.new-message {
    display: flex;
    font-family: 'OpenSans-Bold', sans-serif;
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    font-size: 17px;
    align-items: center;
    position: relative;
    top: 43%;
    height: 10px;
  }

  #messages-container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    left: 100%;
    z-index: 16;
  }
  .message-container{
    border: none;
  }

  .message-top{
    margin: 0px;
  }

  .chat-button {
    position: relative;
    border-right: 0;
    border-radius: 5px 0px 0px 5px;
    float: right;

  }

  .new-message {
    -webkit-animation: pulse 1500ms infinite;
    -moz-animation: pulse 1500ms infinite;
    -o-animation: pulse 1500ms infinite;
    animation: pulse 1500ms infinite;
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(18, 204, 139, 0.4);
      box-shadow: 0 0 0 0 rgba(18, 204, 139, 0.4);
      background-color: rgba(18, 204, 139, 0.6);
    }
    70% {
      -moz-box-shadow: 0 0 0 20px rgba(18, 204, 139, 0);
      box-shadow: 0 0 0 20px rgba(18, 204, 139, 0);
      background-color: rgba(18, 204, 139, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(18, 204, 139, 0);
      box-shadow: 0 0 0 0 rgba(18, 204, 139, 0);
      background-color: rgba(18, 204, 139, 0);

    }
  }
}

@media(max-width: 515px) {
  .modal-box {
    min-width: auto !important;
    width: 95% !important;
    margin: auto;
  }

  #sms-subscription-form-container {
    width: auto;
  }
}
