@import '~bootstrap/scss/bootstrap';
@import '~material-design-icons/iconfont/material-icons.css';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face{
  font-family: 'OpenSans-Regular';
  src: url('fonts/OpenSansRegular.eot?yvpeos');
  src: url('fonts/OpenSansRegular.eot?#iefix') format('embedded-opentype'),
  url('fonts/OpenSansRegular.woff') format('woff'),
  url('fonts/OpenSansRegular.ttf') format('truetype');
}
@font-face{
  font-family: 'OpenSans-Light';
  src: url('fonts/OpenSans-Light.eot?yvpeos');
  src: url('fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('fonts/OpenSans-Light.woff') format('woff'),
  url('fonts/OpenSans-Light.ttf') format('truetype'),
  url('fonts/OpenSans-Light.svg#webfont') format('svg');
}

@font-face{
  font-family: 'OpenSans-Bold';
  src: url('fonts/OpenSans-Bold.eot?yvpeos');
  src: url('fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/OpenSans-Bold.woff') format('woff'),
  url('fonts/OpenSans-Bold.ttf') format('truetype'),
  url('fonts/OpenSans-Bold.svg#webfont') format('svg');
}

@font-face{
  font-family: 'Cubano';
  src: url('fonts/cubano-regular-webfont.eot?yvpeos');
  src: url('fonts/cubano-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/cubano-regular-webfont.woff') format('woff'),
  url('fonts/cubano-regular-webfont.ttf') format('truetype'),
  url('fonts/cubano-regular-webfont.svg#webfont') format('svg');
}

@font-face {
  font-family: 'ClanPro-Book';
  src: url('fonts/FF Clan Pro Wide Book.otf') format('opentype');
}

@font-face {
  font-family: 'ClanPro-News';
  src: url('fonts/FF Clan Pro Wide News.otf') format('opentype');
}

@font-face {
  font-family: 'ClanPro-Bold';
  src: url('fonts/FF Clan Pro Wide Bold.otf') format('opentype');
  font-weight: bold;
}


.material-icons {
  border: none !important;
  height: 0;
  padding: 0 !important;
}

.trunkrs-redirect {
  font-size: 0.9em;
  font-family: OpenSans-Regular, sans-serif;
  height: 46px;
  width: 152px;
  border-radius: 50px;
  border: 0;
  color:#808080 !important;
  background-color: #ffffff;

  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 10px 5px;
}

.trunkrs-redirect:hover {
  color: #92d400 !important;
  transition: 0.25s ease-in-out;
  cursor: pointer;
}
a.trunkrs-redirect, a.trunkrs-redirect:visited, a.trunkrs-redirect:hover, a.trunkrs-redirect:active {
  color: inherit;
}

.logo {
  width: 100%;
  height: 100%;
}

.trunkrs-logo{
  display: block;
  /*position: absolute;*/
  /*width: 190px;*/
  /*height: 190px;*/
  background-image: url(images/trunkrs-logo.png);
}

.trunkrs-logo.plain {
  width: auto;
  height: 100%;
  background: url(images/trunkrs-logo-only.png) center;
  background-size: contain;
  background-repeat: no-repeat;
}

.trunkrs-logo-inline {
  display: block;
  width: 165px;
  height: 70px;
  background-image: url(images/trunkrs-logo.png);
  background-size: cover;
  background-position-y: center;
}

.flat-btn {
  font-family: 'Cubano', sans-serif;
  font-size: 1em;
  height: 60px;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
  width: 100%;
  transition: .3s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 0;
  outline: none !important;
  background-color: #DDDDDD;
}

.flat-btn.disabled {
  pointer-events: none;
  opacity: .5;
}

.flat-btn:hover{
  background-color: #aaaaaa;
}

.flat-btn.green {
  color: #FFFFFF;
  background-color: #12cc8b !important;
}

.flat-btn.green:hover {
  background-color: #0fad76 !important;
}

.step-progress {
  position: relative;
  padding-left: 45px;
  list-style: none;
}
.step-progress li {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
}
.step-progress li:first-child {
  justify-content: flex-start;
}
.step-progress li:last-child {
  justify-content: flex-end;
  top: -10px;
  margin-bottom: 15px;
}
.step-progress li:before {
  display: inline-block;
  content: '';
  position: absolute;
  margin-left: -34px;
  /*margin-top: -3px;*/
  height: 100%;
  width: 100%;
  border-left: 2px solid #CCCCCC;
}
.step-progress li .step-circle {
  display: inline-block;
  position: absolute;
  left: -40px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  background-color: #FFFFFF;
}
.step-progress li .step-circle:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: -4px;
  left: -4px;
  width: 18px;
  height: 18px;
  border: 2px solid #CCCCCC;
  border-radius: 50%;
  background-color: #FFFFFF;
}
.step-progress li.complete:before {
  border-left-color: #12CC8B;
}
.step-progress li.complete .step-circle {
  background-color: #12CC8B;
  /*top: -2px;*/
  /*left: -41px;*/
}

.step-progress li.complete .step-circle:after {
  border-color: #12CC8B;
  background-color: transparent;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
/* 0.82s animation duration with target delay of 5 seconds */
@keyframes shake-with-delay {
  0% , 85.91% { -webkit-transform: translate(0px, 0px) rotate(0deg); }
  87.19% { -webkit-transform: translate(2px, 1px) rotate(0deg); }
  88.47% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
  89.75% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
  91.03% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
  92.31% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
  93.59% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); }
  94.87% { -webkit-transform: translate(-3px, 1px) rotate(0deg); }
  96.15% { -webkit-transform: translate(2px, 1px) rotate(-1deg); }
  97.43% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
  98.71% { -webkit-transform: translate(2px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fade-out {
  from { opacity: 1; }
  to   { opacity: 0; }
}
