.gradient-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  background: linear-gradient(90deg, #7095e5, #47caf4, #62ebdd);
}

body {
  background: url(images/background-cornered.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.box {
  margin: 50px auto 0 auto;
  max-width: 800px;
  border-radius: 10px;
}

.login-form {
  background: transparent;
  padding: 15px;
  font-family: 'Cubano', sans-serif;
  width: 80%;
  max-width: 500px;

  .error {
    min-height: 40px;
    font-size: 1em;
    margin: 0 0 10px;
    box-sizing: border-box;
    color: #C02E6B;
    text-align: center;

    &.help-info {
      color: #808080;
      font-family: OpenSans-Regular, sans-serif;
      text-align: left;
      font-size: 12pt;
      transition: 0.6s;

      a{
        color: #62eaf9;
        text-decoration: none;
        outline: none;
        font-weight: bold;
      }

      a:hover{
        text-decoration: underline;
      }

      p{
        margin: 0;
        padding: 10px;
      }
    }
  }
}

.login-form .wrapper {
  margin: 40px 0 40px 0;
}

.login-header {
  color: #000000;
  font-size: 2.5em;
  text-transform: uppercase
}
.login-subheader {
  color: #807f7f;
  font-size: 20px;
  margin: 12px 0 10px;
  font-family: OpenSans-Bold, sans-serif;
}

.postal-nr {
  width: 70%;
  float: left;
  height: 50px;
}
.postal-code {
  width: 25%;
  float: right;
  height: 50px;
}
.clear {
  clear: both;
}

.login-form .wrapper > button{
  margin-top: 30px;
}

.help {
  text-align: center;
  color: #0081B8;
  margin: 40px 0 0;
  font-size: .8em;
}
.help .link {
  cursor: pointer;
}
.help .link:hover {
  text-decoration: underline;
}
.intercom-msg {
  color: #626262 !important;
  pointer-events: none !important;
}

.top-wrapper{
  min-height: 90%;
}
.bottom-wrapper{
  height: 10%;
}

.ext-site-m {
  padding-left: 15px;
  padding-bottom: 15px;
}

.ext-site-m a {
  padding-left: 15px;
  margin: 5px;
}

.translate-notice{
  font-size: 1em;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin: 35px 0 25px 0;
}

@media (max-width: 768px) {
  body {
    background: url(images/log-in-background-mobile.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #log-in-form .h-25 {
    height: 10% !important;
  }

  #log-in-form .h-75 {
    height: 85% !important;
  }

  #log-in-form .ext-site{
    display: none !important;
  }

  #log-in-form .logo{
    height: 100%;
    padding-top: 20px;
    display: block;
    text-align: center;
  }

  #log-in-form .trunkrs-logo.plain {
    display: block;
    min-height: 100% !important;
    height: 100% !important;
    width: auto;
    margin: 0 auto;
    position: static !important;
    background-repeat: no-repeat;
  }

  #log-in-form .login-header{
    font-size: 1.5em;
  }

  #log-in-form .login-subheader {
    font-size: 1em;
    margin-top: 5px;
  }

  #log-in-form .login-form {
    padding: 0 !important;
    width: 95%;
  }

  #log-in-form  .wrapper > button{
    margin-top: 20px;
    width: 100% !important;
  }

  #log-in-form .error.help-info{
    font-size: 0.9em !important;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 5px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(192,192,192,1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(192,192,192,1);
    box-shadow: 0px 2px 5px 0px rgba(192,192,192,1);
  }

  .login-form .wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0;
  }

  #log-in-form .box{
    padding-top: 30px;
    margin-top: 0;
    min-height: 100%;
  }

  #log-in-form .help{
    padding-bottom: 5px;
    margin: auto;
    width: inherit;
  }
}

.input-holder {
  height: 65px;
  width: 100%;
  padding: 2px 15px;
  border-radius: 7px;
  box-sizing: border-box;
  transition: .6s;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(202,202,202,1);
  -moz-box-shadow: 0px 2px 5px 0px rgba(202,202,202,1);
  box-shadow: 0px 2px 5px 0px rgba(202,202,202,1);
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  outline: none !important;

  &:focus {
    -webkit-box-shadow: 0px 2px 5px 1px rgba(152,152,152,1);
    -moz-box-shadow: 0px 2px 5px 1px rgba(152,152,152,1);
    box-shadow: 0px 2px 5px 1px rgba(152,152,152,1);
  }

  &.has-error{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    border: 1px solid #D32D4F;

    //input{
      color: #D32D4F;
    //}
  }

  //& > input {
  //  border: none;
  //  border-radius: 5px;
    //height: 100%;
    //max-height: 100%;
    //width: 100%;
    max-width: 100%;
    //outline: none;
    //padding: 0 15px;
    //box-sizing: border-box;
    font-size: 1.2em;
    font-family: 'Cubano', sans-serif;

    &::placeholder {
      color: #808080;
    }
  //}
  margin-bottom: 10px;
}
